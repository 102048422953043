import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule} from '@angular/forms';
import { AppComponent } from './app.component';
import { ProductComponent } from './product/product.component';
import { ContactUsComponent } from './contact-us/contact-us.component';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule } from '@angular/common/http';
import { NgbPaginationModule,
  NgbPopoverModule,
  NgbDropdownModule,
  NgbCollapseModule, 
  NgbCarouselModule,
  NgbAccordionModule} from '@ng-bootstrap/ng-bootstrap';
import {
  HomeComponent,
  HeaderComponent,
  FooterComponent,
  ContactFormComponent,
  ErrorPageComponent,
  SupportComponent
} from '../app';
import { PartnerComponent } from './partner/partner.component';
import { SharedModule } from './shared/shared.module';
import { PrivacyPolicyComponent } from './privacy-policy/privacy-policy.component';
import { TermsOfUsComponent } from './terms-of-us/terms-of-us.component';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { QuicklinkStrategy, QuicklinkModule } from 'ngx-quicklink';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { InvestorsComponent } from './investors/investors.component';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReusableTableComponent } from './components/reuable-table/reuable-table.component';


@NgModule({
  declarations: [
    AppComponent,
    ProductComponent,
    ContactUsComponent,
    HomeComponent,
    HeaderComponent,
    FooterComponent,
    ContactFormComponent,
    ErrorPageComponent,
    PartnerComponent,
    SupportComponent,
    PrivacyPolicyComponent,
    TermsOfUsComponent,
    InvestorsComponent,
    ReusableTableComponent
    
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    ReactiveFormsModule,
    HttpClientModule,
    BrowserAnimationsModule,
    NgbModule,
    NgbPaginationModule,
    NgbCarouselModule,
    NgbPopoverModule,
    MatTableModule,
    MatPaginatorModule,
    MatSortModule,
    MatCheckboxModule,
    MatFormFieldModule,
    MatInputModule,
    NgbDropdownModule,
    NgbAccordionModule,
    NgbCollapseModule,
    SharedModule,
    QuicklinkModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production })
  ],
  providers: [{ provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule {}
