import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplyJobFormComponent } from '../components/apply-job-form/apply-job-form.component';
import { CareersComponent } from '../about-us';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { CustomerLogoComponent } from '../components/customer-logo/customer-logo.component';
import { ContactOurTeamComponent } from '../components/contact-our-team/contact-our-team.component';
import { AutofocusDirective } from '../helper/dirctives/autofocus.directive';
import { RouterModule } from '@angular/router';

@NgModule({
  imports: [
    CommonModule,
    NgbModule,
    ReactiveFormsModule,
    FormsModule,
    HttpClientModule,
    RouterModule,
    
  ],
  declarations: [
    ApplyJobFormComponent,
    CareersComponent,
    CustomerLogoComponent,
    ContactOurTeamComponent,
    AutofocusDirective
  ],
  exports: [
    ApplyJobFormComponent,
    CareersComponent,
    CustomerLogoComponent,
    ContactOurTeamComponent,
    AutofocusDirective
  ]
})
export class SharedModule {}
