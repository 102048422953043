import { Component, OnInit } from '@angular/core';
import { YOUTUBE_ICON, LINKEDIN_ICON, FACEBOOK_ICON, CHAT_ICON, TWITTER_ICON
} from '../../../assets/assets_constant';
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {
  youtubeIcon = YOUTUBE_ICON;
  linkedinIcon = LINKEDIN_ICON;
  facebookIcon = FACEBOOK_ICON;
  twitterIcon = TWITTER_ICON;
  chatIcon = CHAT_ICON;
  constructor() { }

  ngOnInit() {
  }

}
