import { Component, OnInit } from '@angular/core';
import { EASY_MIGRATION_IMAGE_PATH, LOCATIONICON, CONTACT_US_BG } from '../../assets/assets_constant';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.css']
})
export class ContactUsComponent implements OnInit {
  imagePath = EASY_MIGRATION_IMAGE_PATH;
  loactionIcon = LOCATIONICON;
  bannerImage = CONTACT_US_BG;

  constructor(private router: ActivatedRoute) { }

  ngOnInit() {
    const value = this.router.snapshot.queryParams['partner'];
  }


}
