import { Component, OnInit } from '@angular/core';
import {
  EASY_MIGRATION_IMAGE_PATH,
  FAQ_BG,
  FAQ_Banner
} from '../../assets/assets_constant';
@Component({
  selector: 'app-support',
  templateUrl: './support.component.html',
  styleUrls: ['./support.component.css']
})
export class SupportComponent implements OnInit {
  imagePath = EASY_MIGRATION_IMAGE_PATH;
  bannerImage: String = FAQ_Banner;

  constructor() { }

  ngOnInit() { }
}
