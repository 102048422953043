import { Component, OnInit } from '@angular/core';
import {
  EASY_MIGRATION_IMAGE_PATH,
  BANNER_IMG,
  CAREERTESTIMGONE,
  CAREERTESTIMGTWO,
  CAREERTESTIMGTHREE,
  CAREERTESTIMGFOUR,
  CAREERTESTIMGFIVE,
  CAREER
} from '../../../assets/assets_constant';


@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  styleUrls: ['./careers.component.css']
})
export class CareersComponent implements OnInit {
  imagePath = EASY_MIGRATION_IMAGE_PATH;
  bannerImage: String = CAREER;
  careerTestImgOne = CAREERTESTIMGONE;
  careerTestImgTwo = CAREERTESTIMGTWO;
  careerTestImgThree = CAREERTESTIMGTHREE;
  careerTestImgFour = CAREERTESTIMGFOUR;
  careerTestImgFive = CAREERTESTIMGFIVE;
  constructor() { }

  ngOnInit() {
  }

}
