import { Component, OnInit } from '@angular/core';
import { FAQ_BG, EPPSSTORYIMG } from '../../../assets/assets_constant';
@Component({
  selector: 'app-why-epps',
  templateUrl: './why-epps.component.html',
  styleUrls: ['./why-epps.component.css']
})
export class WhyEppsComponent implements OnInit {
	bannerImage: String = FAQ_BG;
	eppsStoryBg = EPPSSTORYIMG;
  constructor() { }

  ngOnInit() {
  }

}
