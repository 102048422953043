import { Component, OnInit } from '@angular/core';
import { CustomerLogoService } from './customer-logo.service';

@Component({
  selector: 'app-customer-logo',
  templateUrl: './customer-logo.component.html',
  styleUrls: ['./customer-logo.component.css']
})
export class CustomerLogoComponent implements OnInit {
  allLogos: any;
  constructor(private customerLogoService: CustomerLogoService) { }

  ngOnInit() {
    this.getAllCustomerLogo();
  }

  getAllCustomerLogo() {
    this.customerLogoService.getAllCustomerLogo().subscribe(response => {
      this.allLogos = response.data;
    });
  }
  
}
