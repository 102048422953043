import { Component, OnInit, Input } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-contact-our-team',
  templateUrl: './contact-our-team.component.html',
  styleUrls: ['./contact-our-team.component.css']
})
export class ContactOurTeamComponent implements OnInit {
  @Input() isContactUsPage: boolean = false;
  constructor(private router: Router) { }

  ngOnInit() {
  }

  sendEvent() {
    if (this.isContactUsPage) {
      window.scroll({
        top: 20,
        left: 0,
        behavior: 'smooth'
      });
    } else {
      console.log("redirect");
      this.router.navigate(['contact-us']);
    }
  }
}
