import { Component, OnInit } from '@angular/core';
import { ERROR_PAGE } from '../../assets/assets_constant';
@Component({
  selector: 'app-error-page',
  templateUrl: './error-page.component.html',
  styleUrls: ['./error-page.component.css']
})
export class ErrorPageComponent implements OnInit {
  errorImagePath = ERROR_PAGE;
  constructor() { }

  ngOnInit() {
  }

}
