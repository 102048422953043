import { Component, OnInit, Input } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ContactService } from './contact.service';
import { interval } from 'rxjs';
@Component({
  selector: 'app-contact-form',
  templateUrl: './contact-form.component.html',
  styleUrls: ['./contact-form.component.css']
})
export class ContactFormComponent implements OnInit {
  contactForm: FormGroup;
  submitted: Boolean = false;
  response_message: any;
  rep_color_code: string;
  @Input() type: String;

  constructor(private formBuilder: FormBuilder, private contactService: ContactService) { }

  ngOnInit() {
    this.init();
  }

  get f() {
    return this.contactForm.controls;
  }
  init() {
    this.contactForm = this.formBuilder.group({
      firstname: ['', [Validators.maxLength(200), Validators.minLength(3), Validators.required]],
      lastname: ['', [Validators.maxLength(200), Validators.minLength(3), Validators.required]],
      emailid: ['', [Validators.email, Validators.required]],
      mobilenumber: ['', [Validators.required, Validators.minLength(10),
      Validators.maxLength(12), Validators.pattern('^(0|[1-9][0-9]*)$')]],
      companyname: ['', [Validators.minLength(2), Validators.required]],
      country: ['', [Validators.maxLength(100), Validators.required]],
      partner_yn: ['', [Validators.required]],
      comment: ['', [Validators.maxLength(300)]],
      newslettersubcription: ['', [Validators.maxLength(300)]]
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.contactForm.invalid) {
      return;
    }

    this.contactService.saveContactUsForm(this.contactForm.value).subscribe(
      (data: any) => {
        this.rep_color_code = 'green';
        this.response_message = data.message;
        setTimeout(() => {
          this.resetForm();
        }, 4000);
      },
      error => {
        this.rep_color_code = 'red';
        this.response_message = error.error.comment;
      }
    );

  }
  resetForm() {
    this.contactForm.reset();
    this.submitted = false;
    this.response_message = null;
  }
}
