import { Component, OnInit } from '@angular/core';
import {
  TERMS_OF_USE_BG
} from '../../assets/assets_constant';
@Component({
  selector: 'app-terms-of-us',
  templateUrl: './terms-of-us.component.html',
  styleUrls: ['./terms-of-us.component.css']
})
export class TermsOfUsComponent implements OnInit {
  bannerImage = TERMS_OF_USE_BG;
  constructor() { }

  ngOnInit() {
  }

}
