import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute, Router, Event, NavigationStart, NavigationEnd, NavigationError } from '@angular/router';
import { HEADER_BLUE_LOGO } from '../../../assets/assets_constant';
import { HEADER_WHITE_LOGO } from '../../../assets/assets_constant';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {
  @Input() headerLogo;
  isCollapsedButton = false; // inital Load toggeler off
  isHomePage = true;
  isContactUsPage = false;
  @Output() isCollapsed  = new EventEmitter();
  constructor(private router: Router) {
    this.router.events.subscribe( (event: Event) => {
      if (event instanceof NavigationStart) {
       if (!(event.url === '/')) {
         this.headerLogo = HEADER_BLUE_LOGO;
         this.isHomePage = false;
       } else {
         this.isHomePage = true;
         this.headerLogo = HEADER_WHITE_LOGO;
       }
       if(event.url === '/contact-us'){ 
        this.isContactUsPage = true;
       }else {
        this.isContactUsPage = false;
       }
      }
  });
    this.isCollapsed.emit(!this.isCollapsed);
  }

  ngOnInit() {}

  onToggle() {
    this.isCollapsedButton = !this.isCollapsedButton;
    this.isCollapsed.emit(this.isCollapsedButton);
  }
}
