import { Component, OnInit } from '@angular/core';
import {
  EASY_MIGRATION_IMAGE_PATH,
  PARTNER_BG,
  ECO_SYS_BG,
  PARTNER_MAP_IMG,
  MAP_DETAILS,
  SMART_ERP_PARNER_PROG,
  BENEFITS_OF_PATNER,
  PARTNER_PROGRAM,
  PATNER_GET_STAETED_IMAGE
} from '../../assets/assets_constant';
@Component({
  selector: 'app-partner',
  templateUrl: './partner.component.html',
  styleUrls: ['./partner.component.css']
})
export class PartnerComponent implements OnInit {
  imagePath = EASY_MIGRATION_IMAGE_PATH;
  bannerImage: String = PARTNER_BG;
  ecosysBgImage = ECO_SYS_BG;
  partnerMapImg = PARTNER_MAP_IMG;
  mapDetails = MAP_DETAILS;
  smartErpPartnerProg = PARTNER_PROGRAM;
  benefitsOfPatner = BENEFITS_OF_PATNER;
  getstarted = PATNER_GET_STAETED_IMAGE;
  constructor() { }

  ngOnInit() { }
}
