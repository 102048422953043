import { Directive, ElementRef, AfterContentInit } from '@angular/core';

@Directive({
  selector: '[appAutofocus]'
})
export class AutofocusDirective implements AfterContentInit{

  constructor(public el: ElementRef) { }
  ngAfterContentInit() {
    this.el.nativeElement.focus();
   }
}
