import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from './../../../environments/environment.prod';
@Injectable({
  providedIn: 'root'
})
export class LocationApiService {


  constructor(public http: HttpClient) { }

  public getIPAddress(lat, long) {
    return this.http.get(`https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${environment.googleMapKey}`);
  }
}
