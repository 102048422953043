import { Component, OnInit } from '@angular/core';
import {
  PRIVACY_POLICY_BANNER_BG
} from '../../assets/assets_constant';
@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.css']
})
export class PrivacyPolicyComponent implements OnInit {
  bannerImage = PRIVACY_POLICY_BANNER_BG;
  constructor() { }

  ngOnInit() {
  }

}
