import { Component, OnInit } from '@angular/core';
import {
  EASY_MIGRATION_IMAGE_PATH,
  BANNER_IMG,
  EPPS_ERP_SD_APP_ICON,
  EPPS_ERP_APP_ICON,
  APP_STORE_BADGE,
  GOOGLE_PLAY_BADGE,
  PRODUCT_BG,
  FINANCE_ACCOUNTING_ICON,
  PRODUCT_QUALITY_ICON,
  OUTSOURCING_SUBCONTRACTING_ICON,
  MOBILE_APP_ICON,
  MATERIA_MGMT_ICON,
  JOBWORK_MGMT_ICON,
  HUMAN_RES_MGMT_ICON,
  SALES_DIST_MGMT_ICON,
  BUSINESS_POLICY_MGMT_ICON,
  ADMINISTRATION_MGMT_ICON,
  OURCUSTOIMGONE,
  OURCUSTOIMGTWO,
  PRODEPPSOFFERINGSIMG,
  RIGHTARROWBLACK,
  MOBILEAPPIMG,
  OFFERINGS_FIG,
  PRODUCT_IMAGE,
  BARCODE_IMAGE,
  WIFI,
  BATTERY,
  RANGE,
  SEAMLESS_EXTENSIBILITY_THROUGH_API,
  EPPS_ERP_APPLE_BAR,
  EPPS_ERP_SD_APPLE_BAR,
  EPPS_ERP_GOOGLE_BAR,
  EPPS_ERP_SD_GOOGLE_BAR
} from '../../assets/assets_constant';
import { DomSanitizer } from '@angular/platform-browser';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.css']
})
export class ProductComponent implements OnInit {
  eppsERPApple=EPPS_ERP_APPLE_BAR;
  eppsERPSDApple=EPPS_ERP_SD_APPLE_BAR;
  eppsERPGOOGLE=EPPS_ERP_GOOGLE_BAR;
  eppsERPSDGoogle=EPPS_ERP_SD_GOOGLE_BAR;
  imagePath = EASY_MIGRATION_IMAGE_PATH;
  bannerImage = '../../assets/banner-img.jpg';
  eppsERPAppIcon = EPPS_ERP_APP_ICON;
  eppsERPAppSdIcon = EPPS_ERP_SD_APP_ICON;
  appStoreBadge = APP_STORE_BADGE;
  googlePlayBadge = GOOGLE_PLAY_BADGE;
  productBgImg = PRODUCT_BG;
  financeAccIcon = FINANCE_ACCOUNTING_ICON;
  prodQuaIcon = PRODUCT_QUALITY_ICON;
  outsourcingSubcontracionIcon = OUTSOURCING_SUBCONTRACTING_ICON;
  mobileAppIcon = MOBILE_APP_ICON;
  materialMgmtIcon = MATERIA_MGMT_ICON;
  jobWOrkMgmtIcon = JOBWORK_MGMT_ICON;
  humanResMgmtIcon = HUMAN_RES_MGMT_ICON;
  salesDistMgmtIcon = SALES_DIST_MGMT_ICON;
  businessPolicyMgmtIcon = BUSINESS_POLICY_MGMT_ICON;
  administartionMgmtIcon = ADMINISTRATION_MGMT_ICON;
  ourCustImgOne = OURCUSTOIMGONE;
  ourCustImgTwo = OURCUSTOIMGTWO;
  prodEppsOffrImg = PRODEPPSOFFERINGSIMG;
  rightArrowBalck = RIGHTARROWBLACK;
  mobileAppImg = MOBILEAPPIMG;
  offeringsFig = OFFERINGS_FIG;
  safeURL: any;
  product_gif = '../../assets/gif/product-gif.mp4';
  productImage = PRODUCT_IMAGE;
  barcodeImage = BARCODE_IMAGE;
  wifi = WIFI;
  battery = BATTERY;
  range = RANGE;
  seamlessExtensibilityThroughApi = SEAMLESS_EXTENSIBILITY_THROUGH_API
  constructor(private _sanitizer: DomSanitizer) {
    this.safeURL = this._sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/pohN2bmZWdE');
  }

  ngOnInit() { }
}
