import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CareersComponent } from './about-us';
import {
  HomeComponent,
  ContactUsComponent,
  PartnerComponent,
  ErrorPageComponent,
  SupportComponent,
  ProductComponent,
  TermsOfUsComponent,
  PrivacyPolicyComponent
} from '../app';
import { QuicklinkStrategy } from 'ngx-quicklink';
import { InvestorsComponent } from './investors/investors.component';

const routes: Routes = [
  { path: '', component: HomeComponent },
  { path: 'resources', loadChildren: () => import('./resources/resources.module').then(m => m.ResourcesModule)},
  { path: 'about-us', loadChildren: () => import('./about-us/about-us.module').then(m => m.AboutUsModule)},
  { path: 'product', component: ProductComponent },
  { path: 'partner', component: PartnerComponent },
  { path: 'support', component: SupportComponent },
  { path: 'contact-us', component: ContactUsComponent },
  { path: 'investor-relations', component: InvestorsComponent },
  { path: 'careers', component: CareersComponent },
  { path: 'terms-of-us', component: TermsOfUsComponent },
  { path: 'privacy-policy', component: PrivacyPolicyComponent },
  { path: '**', component: ErrorPageComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, scrollPositionRestoration: 'top', preloadingStrategy: QuicklinkStrategy })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
