import { Component, Input, OnInit } from '@angular/core';
import { HostListener, Inject } from '@angular/core';
import {
  trigger,
  state,
  transition,
  style,
  animate
} from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { HEADER_WHITE_LOGO, HEADER_BLUE_LOGO } from '../assets/assets_constant';
import { Router, Event, NavigationStart, RouterEvent, NavigationEnd, NavigationCancel, NavigationError } from '@angular/router';
import { LocationApiService } from './helper/service/location-api.service';
import { LoaderService } from './shared/service/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  animations: [
    trigger('ease-in-out', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate(200)]),
      transition(':leave', [animate(200)])
    ])
  ]
})
export class AppComponent implements OnInit {
  headerLogoPath = HEADER_WHITE_LOGO;
  isHomePage = true;
  isShow = false;
  topPosToStartShowing = 100;
  isLoading = false;
  @Input() toggleButton = false;
  constructor(@Inject(DOCUMENT) document, public router: Router, private locationApiService: LocationApiService, private loaderService: LoaderService) {
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationStart) {
        if (!(event.url === '/')) {
          this.isHomePage = false;
        } else {
          this.isHomePage = true;
        }
      }
    });
    this.router.events.subscribe((event: RouterEvent) => {
      this.navigationInterceptor(event)
    })
  }

  ngOnInit(): void {
    // this.checkIpfromIndia();
    this.loaderService.getLoader().subscribe((data)=> {
      this.isLoading = data;
    })
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll(e) {
    if (this.isHomePage) {
      if (window.pageYOffset > 50) {
        this.headerWhite();
      } else {
        this.headerTrasperent();
      }
      this.toggleButton = window.pageXOffset > 767 ? false : true;
    }
    // get scroll position
    const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
    // Check weather window is scrolled or not.
    if (scrollPosition >= this.topPosToStartShowing) {
      this.isShow = true;
    } else {
      this.isShow = false;
    }
  }
  onToggleChange(event) {
    if (event) {
      this.headerWhite();
      this.headerLogoPath = HEADER_BLUE_LOGO;
    } else {
      if (!(window.pageYOffset > 50)) {
        this.headerTrasperent();
        this.headerLogoPath = HEADER_BLUE_LOGO;
      }
    }
  }
  gotoTop() {
    window.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
  headerWhite() {
    const element = document.getElementById('navbar');
    (element) ? element.classList.remove('unsticky') : ''; // if element 
    (element) ? element.classList.add('sticky') : '';
    this.headerLogoPath = HEADER_BLUE_LOGO;
  }
  headerTrasperent() {
    const element = document.getElementById('navbar');
    (element) ? element.classList.remove('sticky') : ''; // if element 
    (element) ? element.classList.add('unsticky') : '';
    this.headerLogoPath = HEADER_WHITE_LOGO;
  }

  checkIpfromIndia() {
    if ('geolocation' in navigator) {
      navigator.geolocation.getCurrentPosition((position) => {
        if (position.coords.latitude && position.coords.longitude) {
          this.locationApiService.getIPAddress(position.coords.latitude, position.coords.longitude).subscribe((res: any) => {
            // this.ipAddress = res.ip;
            console.log(res);
          });
        }
      });
    }
  }

  navigationInterceptor(event: RouterEvent): void {
    if (event instanceof NavigationStart) {
      this.loaderService.setLoader('true');
      this.isLoading = true;
    }
    if (event instanceof NavigationEnd) {
      setTimeout(() => {
        this.loaderService.setLoader('false');
        this.isLoading = false;
      }, 1400);
    }

    // Set loading state to false in both of the below events to hide the spinner in case a request fails
    if (event instanceof NavigationCancel) {
      this.loaderService.setLoader('false');
      this.isLoading = false;
    }
    if (event instanceof NavigationError) {
      this.loaderService.setLoader('false');
      this.isLoading = false;
    }
  }
}
