import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CustomerLogoService {

  constructor(private http: HttpClient) { }
  getAllCustomerLogo() {
    return this.http.get<any>(environment.mock_json + 'all-customer-logo.json');
  }
}
