import { Component, OnInit, Input } from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ApplyJobService } from './apply-job.service';

@Component({
  selector: 'app-apply-job-form',
  templateUrl: './apply-job-form.component.html',
  styleUrls: ['./apply-job-form.component.css']
})
export class ApplyJobFormComponent implements OnInit {
  @Input() jobId;
  modelFlag = true;
  careerForm: FormGroup;
  submitted;
  supportedFileTypes: Array<any> = ['DOC', 'DOCX', 'PDF'];
  selectedFiles: any;
  fileType: any;
  formData = new FormData();
  disabledButton = false;
  rep_color_code: string;
  response_message: any;
  constructor(
    private modalService: NgbModal,
    private formBuilder: FormBuilder,
    private applyJobService: ApplyJobService
  ) {}

  ngOnInit() {
    this.init();
  }
  get f() {
    return this.careerForm.controls;
  }
  init() {
    this.careerForm = this.formBuilder.group({
      firstname: ['', [Validators.maxLength(200), Validators.minLength(3), Validators.required]],
      lastname: ['', [Validators.maxLength(200), Validators.minLength(3), Validators.required]],
      emailid: ['', [Validators.email, Validators.required]],
      phonenumber: [
        '',
        [
          Validators.required,
          Validators.minLength(10),
          Validators.maxLength(12),
          Validators.pattern('^(0|[1-9][0-9]*)$')
        ]
      ],
      location: ['', [Validators.required, Validators.minLength(2)]],
      expectedsalary: [
        '',
        [Validators.required, Validators.maxLength(11), Validators.pattern('^[0-9]*$')]
      ],
      noticeperiodinmonth: [
        '',
        [Validators.required, Validators.min(0), Validators.max(120), Validators.pattern('^[0-9]*$')]
      ],
      file: []
    });
  }

  onSubmit() {
    this.disabledButton = true;
    this.submitted = true;
    if (this.careerForm.invalid) {
      this.disabledButton = false;
      return;
    }
    this.formData.append('firstname', this.f.firstname.value);
    this.formData.append('lastname', this.f.lastname.value);
    this.formData.append('emailid', this.f.emailid.value);
    this.formData.append('phonenumber', this.f.phonenumber.value);
    this.formData.append('location', this.f.location.value);
    this.formData.append('expectedsalary', this.f.expectedsalary.value);
    this.formData.append(
      'noticeperiodinmonth',
      this.f.noticeperiodinmonth.value
    );
    this.formData.append('jobId', this.jobId);
    this.formData.append('file', (this.selectedFiles) ? this.selectedFiles[0] : '');
    this.applyJobService.saveJob(this.formData).subscribe(
      (data: any) => {
        this.rep_color_code = 'green';
        this.response_message = data.message;
        this.modalService.dismissAll();
        setTimeout(() => {
          this.careerForm.reset();
          this.disabledButton = false;
        }, 4000);
      },
      error => {
        this.rep_color_code = 'red';
        this.response_message = error.error.comment;
      }
    );
  }
  open(content) {
    this.modalService.open(content, { ariaLabelledBy: 'modal-basic-title' });
  }

  selectFile(event) {
    this.selectedFiles = event.target.files;
    if (this.selectedFiles.length) {
      this.fileType = this.selectedFiles[0].name
        .split(/\./g)
        [this.selectedFiles[0].name.split(/\./g).length - 1].toUpperCase();
      if (this.supportedFileTypes.indexOf(this.fileType) !== -1) {
        // Validation is corrent.
      }
    }
  }
}
