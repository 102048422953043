/* Home page */
export const EASY_MIGRATION_IMAGE_PATH = '../../assets/easy_migration.svg';
export const BANNER_IMG = '../../assets/images/career_banner.jpg';
export const HEADER_WHITE_LOGO = '../../assets/images/epps_white_logo.svg';
export const HEADER_BLUE_LOGO = '../../assets/images/epps_blue_logo.svg';
export const EPPS_ERP_PRODUCTIVITY = '../../assets/icons/homeEPPSERPIcons/increased_productivity.svg';
export const BUSINESS_DECISION_ICON = '../../assets/icons/homeEPPSERPIcons/better_business_decision.svg';
export const CONTINUOUES_ACCESSIBILITY = '../../assets/icons/homeEPPSERPIcons/continuous_accessibility.svg';
export const EMPOWERED_EMPLOYEES = '../../assets/icons/homeEPPSERPIcons/empowered_employees.svg';
export const ENHANCED_CUST_ENGAGEMENT = '../../assets/icons/homeEPPSERPIcons/enhanced_customer_engagement.svg';
export const DEPLOYMENT_IMPLEMENTATION = '../../assets/icons/homeEPPSERPIcons/ease_of_use_deployment_and_implementation.svg';
export const CONSULTING_APPROACH = '../../assets/icons/homeEPPSERPIcons/envisaged_consulting_approch.svg';
export const REPORTING_PLANNING = '../../assets/icons/homeEPPSERPIcons/improved_reporting_and_planning.svg';
export const SECURITY_QUALITY = '../../assets/icons/homeEPPSERPIcons/data_security_and_quility.svg';
export const STREAMLINED_PROCESSES = '../../assets/icons/homeEPPSERPIcons/stremlined_and_efficient_processes.svg';
export const INTEGRATED_INFORMATION = '../../assets/icons/homeEPPSERPIcons/integrated_information.svg';
export const ACCURATE_INFORMATION = '../../assets/icons/homeEPPSERPIcons/accurate_forecasting.svg';
export const GST_READY = '../../assets/icons/gst_ready.svg';
export const MULTI_CURRENCY = '../../assets/icons/multi_currency.svg';
export const COMPATIBLE_MULTI = '../../assets/icons/compatible_with_multi.svg';
export const DOC_MANAGEMENT = '../../assets/icons/document_management.svg';
export const MASTER_OPENING = '../../assets/icons/masters_and_opening.svg';
export const THIRD_PARTY_LICENSING = '../../assets/icons/free_of_third_party_licensing.svg';
export const AUDIT_TRAIL_LOG = '../../assets/icons/audit_trail_logs.svg';
export const OPEN_ICON = '../../assets/icons/minus.svg';
export const CLOSE_ICON = '../../assets/icons/plusicon.svg';
export const MULTI_SMART_DEVICE = '../../assets/icons/multi_smart_device.svg';
export const BAR_CODING_FLEXIBLE = '../../assets/icons/bar_coding_flexible_concurrent.svg';
export const NOTIFICATION_FOR_APPROVAL = '../../assets/icons/notification_for_approvals.svg';
export const BROADCAST_NOTIFICATION = '../../assets/icons/broadcast_notification.svg';
export const INITIATIVE_USER_INTERFACE = '../../assets/icons/intuitive_user_interface.svg';
export const MULTI_BROWSER_COMPATIBILITY = '../../assets/icons/multi_browser_compatible.svg';
export const MULTI_LINGUAL_SUPPORT = '../../assets/icons/multi_lingual_support.svg';
export const EMAIL_SMS_INTEGRATION = '../../assets/icons/email_sms_integration.svg';
export const BAR_CODING_QR_CODING = '../../assets/icons/bar_coding_qr_coding.svg';
export const SMART_TCO_IMAGE = '../../assets/images/smart_tco_image.png';
export const SMART_EXPERIENCE = '../../assets/images/smart_experience.png';
export const SMART_TECHNOLOGY = '../../assets/images/smart_technology.png';
export const EPPS_ERP_APP_ICON = '../../assets/images/epps_smart-erp_appicon.svg';
export const EPPS_ERP_SD_APP_ICON = '../../assets/images/epps_smart-erp_sd_appicon.svg';
export const GOOGLE_PLAY_BADGE = '../../assets/images/google-play-badge.svg';
export const APP_STORE_BADGE = '../../assets/images/app-store-badge.svg';
export const PHARMA_INDUSTRY_IMG = '../../assets/images/pharmaceutical-industry-image.png';
export const ECO_SYS_BG = '../../assets/images/partner-eco-sys-bg.png';
export const PARTNER_MAP_IMG = '../../assets/images/partner-map.png';
export const MAP_DETAILS = '../../assets/images/map-detail.png';
export const SMART_ERP_PARNER_PROG = '../../assets/images/epps-smart-partner-program.png';
export const HOME_BANNER_SALES = '../../assets/images/book-your-sales-orders-with-epps-mobile-app.jpg';
export const HOME_BANNER_SALES_MD = '../../assets/images/book-your-sales-orders-with-epps-mobile-app-medium-desktop.jpg';
export const HOME_BANNER_SALES_SM = '../../assets/images/book-your-sales-orders-with-epps-mobile-app-small-desktop.jpg';
export const HOME_BANNER_SALES_XS = '../../assets/images/book-your-sales-orders-with-epps-mobile-app-xSmall-desktop.jpg';
export const HOME_BANNER_INVENTORY = '../../assets/images/manage-your-inventory-with-epps-smart-erp.jpg';
export const HOME_BANNER_INVENTORY_MD = '../../assets/images/manage-your-inventory-with-epps-smart-erp-medium-desktop.jpg';
export const HOME_BANNER_INVENTORY_SM = '../../assets/images/manage-your-inventory-with-epps-smart-erp-small-desktop.jpg';
export const HOME_BANNER_INVENTORY_XS = '../../assets/images/manage-your-inventory-with-epps-smart-xSmall-desktop.jpg';
export const HOME_BANNER_PLATFORM = '../../assets/images/boost-up-your-business-needs-with-a-future-ready-platform.jpg';
export const HOME_BANNER_PLATFORM_MD = '../../assets/images/boost-up-your-business-needs-with-a-future-ready-platform-medium-desktop.jpg';
export const HOME_BANNER_PLATFORM_SM = '../../assets/images/boost-up-your-business-needs-with-a-future-ready-platform-small-desktop.jpg';
export const HOME_BANNER_PLATFORM_XS = '../../assets/images/boost-up-your-business-needs-with-a-future-ready-platform-xSmall-desktop.jpg';
export const HOME_BANNER_ACCURATE_DECISION = '../../assets/images/real-time-business-data-for-accurate-decisions.jpg';
export const HOME_BANNER_INTUITIVEINTELLIGENT = '../../assets/images/intuitive-intelligent-and-agile-large-desktop.jpg';
export const HOME_BANNER_INTUITIVEINTELLIGENT_MD = '../../assets/images/intuitive-intelligent-and-agile-medium-desktop.jpg';
export const HOME_BANNER_INTUITIVEINTELLIGENT_XS = '../../assets/images/intuitive-intelligent-and-agile-xSmall-desktop.jpg';
export const HOME_BANNER_INTUITIVEINTELLIGENT_SM = '../../assets/images/intuitive-intelligent-and-agile-small-desktop.jpg';
export const ABOUTBANNER = '../../assets/images/aboutbanner.png';
export const HOME_REALTIMEBG = '../../assets/images/real-time-banner.jpg';
export const HOME_REALTIMEBG_MD = '../../assets/images/real-time-business-data-for-accurate-decisions-medium-desktop.jpg';
export const HOME_REALTIMEBG_SM = '../../assets/images/real-time-business-data-for-accurate-decisions-small-desktop.jpg';
export const HOME_REALTIMEBG_XS = '../../assets/images/real-time-business-data-for-accurate-decisions-xSmall-desktop.jpg';
export const RUNNING_PARTNER_ICON = '../../assets/icons/running_partner_for_your_digital.svg';
export const MANAGE_HIGH_VOL_ICON = '../../assets/icons/manages_high_volumes.svg';
export const FUTURE_TECH_ICON = '../../assets/icons/fit_for_any_future_technological.svg';
export const EASY_MIGRATION_ICON = '../../assets/icons/easy_migration.svg';
export const YOUTUBE_ICON = '../../assets/icons/youtubeIcon.svg';
export const TWITTER_ICON = '../../assets/icons/twitter.svg';
export const LINKEDIN_ICON = '../../assets/icons/linkedin.svg';
export const FACEBOOK_ICON = '../../assets/icons/facebook.svg';
export const CHAT_ICON = '../../assets/icons/chat.svg';
export const PRODUCT_BG = '../../assets/images/Product-banner.png';
export const BLOG_BG = '../../assets/images/resources-blog-banner.png';
export const CASESTUDY_BG = '../../assets/images/resources-case-study.png';
export const EPPS_OFFERINGS = '../../assets/images/epps-smart-erp-offerings.png';
export const DISTRIBUTION_INDUSTRY = '../../assets/images/distribution-industry-image.png';
export const CONSUMER_PROD_INDUSTRY = '../../assets/images/consumer-product-industry-image.png';
export const RESOURCES_EVENT = '../../assets/images/resources-event-banner.png';
export const PARTNER_BG = '../../assets/images/partner-new-banner.png';
export const FAQ_BG = '../../assets/images/story_banner3.png';
export const ERROR_PAGE = '../../assets/404-error.png';
export const ASHEERKAPOORPHOTO = '../../assets/images/asheer-kapoor.png';
export const ATULPIMPLEPHOTO = '../../assets/images/atul-pimple.png';
export const SANJEEVSABHARWAL = '../../assets/images/Sanjeev-Sabharwal.jpeg';
export const MUKTAAKAMPLIKAR = '../../assets/images/mukta-artfulliving.jpg';
export const GANESHNATRAJAN = '../../assets/images/Ganesh-Natarajan.jpg';
export const POOJAPHOTO = '../../assets/images/pooja.png';
export const JYOTISHPHOTO = '../../assets/images/jyotish.png';
export const MILINDPHOTO = '../../assets/images/milind.png';
export const LEADERSHIPBG = '../../assets/images/Leadership-banner.png';
export const LINKEDINICON = '../../assets/icons/linkedinIcon.svg';
export const Abhinesh = '../../assets/images/Abhinesh.jpeg';
export const Sourabh = '../../assets/images/Sourabh.jpeg';
export const Harshad = '../../assets/images/Harshad.jpeg';
export const jitendra = '../../assets/images/jitendra.jpeg';
export const Archana = '../../assets/images/Archana.jpg';
export const POSHSLOGO = '../../assets/images/poshs.jpg';
export const AAMODPHOTO = '../../assets/images/aamod.jpeg';
// export const POSHSLOGO = '../../assets/images/poshs.jpg';
export const FINANCE_ACCOUNTING_ICON = '../../assets/images/finance_and_accounting_managment_system.svg';
export const PRODUCT_QUALITY_ICON = '../../assets/images/production_and_quility_managment_system.svg';
export const OUTSOURCING_SUBCONTRACTING_ICON = '../../assets/images/outsourcing_subcontracting_managment_system.svg';
export const MOBILE_APP_ICON = '../../assets/images/mobile_app.svg';
export const MATERIA_MGMT_ICON = '../../assets/images/material_managment_system.svg';
export const JOBWORK_MGMT_ICON = '../../assets/images/jobwork_managment_system.svg';
export const HUMAN_RES_MGMT_ICON = '../../assets/images/human_resource_managment_system.svg';
export const SALES_DIST_MGMT_ICON = '../../assets/images/sales_and_distribution_managment_system.svg';
export const BUSINESS_POLICY_MGMT_ICON = '../../assets/images/business_policy_management_system.svg';
export const ADMINISTRATION_MGMT_ICON = '../../assets/images/administration_management_system.svg';
export const CAREERTESTIMGONE = '../../assets/images/careertest1.png';
export const CAREERTESTIMGTWO = '../../assets/images/careertest2.png';
export const CAREERTESTIMGTHREE = '../../assets/images/careertest3.png';
export const CAREERTESTIMGFOUR = '../../assets/images/careertest4.png';
export const CAREERTESTIMGFIVE = '../../assets/images/careertest5.png';
export const LEFTARROW = '../../assets/icons/left-arrow.svg';
export const RIGHTARROW = '../../assets/icons/arrow-right.svg';
export const WAVEBG = '../../assets/images/eppe-smarterp-running-partner-bg.png';
export const OURCUSTOIMGONE = '../../assets/images/our_custo1.png';
export const OURCUSTOIMGTWO = '../../assets/images/our_custo2.png';
export const RESOURCESBG = '../../assets/images/resources-videos.png';
export const PRODEPPSOFFERINGSIMG = '../../assets/images/epps-offerings.png';
export const RIGHTARROWBLACK = '../../assets/icons/right-arrow-black.svg';
export const FANCYQUOTEICON = '../../assets/icons/fancy_quote_icon.svg';
export const LOCATIONICON = '../../assets/icons/location-map.svg';
export const MOBILEAPPIMG = '../../assets/images/mockup_iphonesegold_portrait.png';
export const EPPSSTORYIMG = '../../assets/images/story.png';
export const CHECKBLUEICON = '../../assets/icons/check.svg';
export const DOTTED_BG_IMG = '../../assets/icons/dotted-img.png';
export const DOTTED_BG_IMG_FULL = '../../../assets/icons/dotted-img-full.png';
export const TERMS_OF_USE_BG = '../../assets/images/terms-of-use-banner.png';
export const CUST_BONY_IND = '../../assets/icons/customer-logos/bony-systems.png';
export const CUST_SURYA_LIFE = '../../assets/icons/customer-logos/surya-life-sciences.png';
export const CALIPER_AUTO = '../../assets/icons/customer-logos/caliper-auto.png';
export const JAYASHREE_ENT = '../../assets/icons/customer-logos/jayashree-enterprices.png';
export const SGCONTROL = '../../assets/icons/customer-logos/SGControl-switchgears.jpg';
export const CUST_ETE_ELE = '../../assets/icons/customer-logos/ete-electrical.png';
export const EDEN_SPORTS = '../../assets/icons/customer-logos/eden-sports.png';
export const PRIVACY_POLICY_BANNER_BG = '../../assets/images/privacy-policy-banners.png';
export const OFFERINGS_FIG = '../../assets/images/ERP_Offerings.png';
export const CONTACT_US_BG = '../../assets/images/contactus_banner_option2.png';
export const PROMPT_SUPPORT_ICON = '../../assets/icons/prompt-support.svg';
export const PRODUCT_IMAGE = '../../assets/images/Mobile-app.png';
export const BARCODE_IMAGE = '../../assets/images/app-barcode1.png';
export const WIFI = '../../assets/icons/wifi.svg';
export const BATTERY = '../../assets/icons/battery.svg';
export const RANGE = '../../assets/icons/range.svg';
export const SEAMLESS_EXTENSIBILITY_THROUGH_API = '../../assets/images/Seamless-Extensibility-Through-API.png';
export const THE_EPPS_SUCCESS_DRIVERS = '../../assets/images/THE-EPPS-SUCCESS-DRIVERS.png';
export const EPPS_SMART_ERP_ADVANTAGE = '../../assets/images/EPPS-SMART-ERP-ADVANTAGE.png'
export const EPPS_BANNER_NEW = '../../assets/images/event-page-banner.png';
export const FAQ_Banner = '../../assets/images/faq_banner.png';
export const LEADING_BANNER_NEW = '../../assets/images/Leadership-banner-new-overlay.png';
export const BENEFITS_OF_PATNER = '../../assets/images/benefits-of-becoming-an-epps-partner.png';
export const CAREER = '../../assets/images/career-banner.png';
export const PARTNER_PROGRAM = '../../assets/images/partner-program.png';
export const ATUL_SIR_NEW_PIC = '../../assets/images/Atul_Pic.png';
export const MILIND_SIR_NEW_PIC = '../../assets/images/Milind_Pic.png';
export const PATNER_GET_STAETED_IMAGE = '../../assets/images/getStarted-right-image.jpg';
export const EPPS_ERP_APPLE_BAR = '../../assets/images/EPPS ERP_Apple_Bar_Code.png';
export const EPPS_ERP_SD_APPLE_BAR = '../../assets/images/EPPS ERP-SD-apple-bar-code.png';
export const EPPS_ERP_GOOGLE_BAR = '../../assets/images/EPPS Mnagement App-google-play.png';
export const EPPS_ERP_SD_GOOGLE_BAR = '../../assets/images/EPPS-SD-google-play.png';
