import { Component, OnInit, AfterViewInit } from '@angular/core';
import AOS from 'aos';
import {
  EASY_MIGRATION_IMAGE_PATH,
  BANNER_IMG,
  SECURITY_QUALITY,
  EPPS_ERP_PRODUCTIVITY,
  BUSINESS_DECISION_ICON,
  CONTINUOUES_ACCESSIBILITY,
  EMPOWERED_EMPLOYEES,
  ENHANCED_CUST_ENGAGEMENT,
  DEPLOYMENT_IMPLEMENTATION,
  CONSULTING_APPROACH,
  REPORTING_PLANNING,
  STREAMLINED_PROCESSES,
  INTEGRATED_INFORMATION,
  ACCURATE_INFORMATION,
  GST_READY,
  MULTI_CURRENCY,
  COMPATIBLE_MULTI,
  DOC_MANAGEMENT,
  MASTER_OPENING,
  THIRD_PARTY_LICENSING,
  AUDIT_TRAIL_LOG,
  MULTI_SMART_DEVICE,
  BAR_CODING_FLEXIBLE,
  NOTIFICATION_FOR_APPROVAL,
  BROADCAST_NOTIFICATION,
  INITIATIVE_USER_INTERFACE,
  MULTI_BROWSER_COMPATIBILITY,
  MULTI_LINGUAL_SUPPORT,
  EMAIL_SMS_INTEGRATION,
  BAR_CODING_QR_CODING,
  SMART_TCO_IMAGE,
  SMART_EXPERIENCE,
  SMART_TECHNOLOGY,
  HOME_BANNER_SALES,
  HOME_BANNER_SALES_MD,
  HOME_BANNER_SALES_SM,
  HOME_BANNER_SALES_XS,
  HOME_BANNER_PLATFORM,
  HOME_BANNER_INVENTORY,
  HOME_BANNER_INVENTORY_MD,
  HOME_BANNER_INVENTORY_SM,
  HOME_BANNER_INVENTORY_XS,
  HOME_REALTIMEBG_MD,
  HOME_REALTIMEBG_SM,
  HOME_REALTIMEBG_XS,
  HOME_BANNER_PLATFORM_MD,
  HOME_BANNER_PLATFORM_SM,
  HOME_BANNER_PLATFORM_XS,
  HOME_BANNER_INTUITIVEINTELLIGENT_MD,
  HOME_BANNER_INTUITIVEINTELLIGENT_SM,
  HOME_BANNER_INTUITIVEINTELLIGENT_XS,
  RUNNING_PARTNER_ICON,
  MANAGE_HIGH_VOL_ICON,
  FUTURE_TECH_ICON,
  EASY_MIGRATION_ICON,
  WAVEBG,
  OURCUSTOIMGONE,
  OURCUSTOIMGTWO,
  HOME_BANNER_ACCURATE_DECISION,
  HOME_REALTIMEBG,
  FANCYQUOTEICON,
  HOME_BANNER_INTUITIVEINTELLIGENT,
  DOTTED_BG_IMG,
  DOTTED_BG_IMG_FULL,
  CUST_BONY_IND,
  CUST_SURYA_LIFE,
  CALIPER_AUTO,
  JAYASHREE_ENT,
  SGCONTROL,
  CUST_ETE_ELE,
  EDEN_SPORTS,
  PROMPT_SUPPORT_ICON,
  THE_EPPS_SUCCESS_DRIVERS,
  EPPS_SMART_ERP_ADVANTAGE,


} from '../../assets/assets_constant';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import { Meta, Title } from '@angular/platform-browser';
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
  providers: [NgbCarouselConfig]
})
export class HomeComponent implements OnInit {
  imagePath = EASY_MIGRATION_IMAGE_PATH;
  bannerImage: String = BANNER_IMG;
  productivityIcon: String = EPPS_ERP_PRODUCTIVITY;
  betterBusinessDecision = BUSINESS_DECISION_ICON;
  continuousAccessibilityIcon = CONTINUOUES_ACCESSIBILITY;
  empoweredEmployeesIcon = EMPOWERED_EMPLOYEES;
  enhancedCustomerEngagementIcon = ENHANCED_CUST_ENGAGEMENT;
  deploymentImplementation = DEPLOYMENT_IMPLEMENTATION;
  consultingApproch = CONSULTING_APPROACH;
  reportingPlanningIcon = REPORTING_PLANNING;
  securityQuilityIcon = SECURITY_QUALITY;
  streamesProcessesIcon = STREAMLINED_PROCESSES;
  integratedInfo = INTEGRATED_INFORMATION;
  accurateInfoIcon = ACCURATE_INFORMATION;
  gstReadyIcon = GST_READY;
  multiCurrencyIcon = MULTI_CURRENCY;
  compatibleMulti = COMPATIBLE_MULTI;
  docManagement = DOC_MANAGEMENT;
  masterOpeningIcon = MASTER_OPENING;
  thirdPartyLicensing = THIRD_PARTY_LICENSING;
  auditTrailLog = AUDIT_TRAIL_LOG;
  multiSmartDevice = MULTI_SMART_DEVICE;
  barCodingFlexible = BAR_CODING_FLEXIBLE;
  notificationForApproval = NOTIFICATION_FOR_APPROVAL;
  broadcastNotification = BROADCAST_NOTIFICATION;
  initiativeInterface = INITIATIVE_USER_INTERFACE;
  multiBrowserCompatibility = MULTI_BROWSER_COMPATIBILITY;
  multiLingualSupport = MULTI_LINGUAL_SUPPORT;
  emailSmsIntegration = EMAIL_SMS_INTEGRATION;
  barCodingQrCoding = BAR_CODING_QR_CODING;
  smartTcoImage = SMART_TCO_IMAGE;
  smartExperiance = SMART_EXPERIENCE;
  smartTechnology = SMART_TECHNOLOGY;
  homeBannerSalesImg: String = HOME_BANNER_SALES;
  homeBannerSalesImgMd: String = HOME_BANNER_SALES_MD;
  homeBannerSalesImgSm: String = HOME_BANNER_SALES_SM;
  homeBannerSalesImgXs: String = HOME_BANNER_SALES_XS;
  homeBannerPlatform: String = HOME_BANNER_PLATFORM;
  homeBannerPlatformMd: String = HOME_BANNER_PLATFORM_MD;
  homeBannerPlatformSm: String = HOME_BANNER_PLATFORM_SM;
  homeBannerPlatformXs: String = HOME_BANNER_PLATFORM_XS;
  homeBannerInventory: String = HOME_BANNER_INVENTORY;
  homeBannerInventryMd: String = HOME_BANNER_INVENTORY_MD;
  homeBannerInventryXs: String = HOME_BANNER_INVENTORY_XS;
  homeBannerInventrySm: String = HOME_BANNER_INVENTORY_SM;
  homeBannerAccurateDecision = HOME_BANNER_ACCURATE_DECISION;
  homeBannerIntuitiveIntelligent: String = HOME_BANNER_INTUITIVEINTELLIGENT;
  homeBannerIntuitiveIntelligentMd: String = HOME_BANNER_INTUITIVEINTELLIGENT_MD;
  homeBannerIntuitiveIntelligentSm: String = HOME_BANNER_INTUITIVEINTELLIGENT_SM;
  homeBannerIntuitiveIntelligentXs: String = HOME_BANNER_INTUITIVEINTELLIGENT_XS;
  homeRealtimeBg: String = HOME_REALTIMEBG;
  homeRealtimeBgMd: String = HOME_REALTIMEBG_MD;
  homeRealtimeBgSm: String = HOME_REALTIMEBG_SM;
  homeRealtimeBgXs: String = HOME_REALTIMEBG_XS;
  easyMigratioIcon = EASY_MIGRATION_ICON;
  manageHighVolIcon = MANAGE_HIGH_VOL_ICON;
  futureTechIcon = FUTURE_TECH_ICON;
  runningPartnerIcon = RUNNING_PARTNER_ICON;
  ourCustImgOne = OURCUSTOIMGONE;
  ourCustImgTwo = OURCUSTOIMGTWO;
  fancyQuoteIcon = FANCYQUOTEICON;
  waveBg = WAVEBG;
  custBoneInds = CUST_BONY_IND;
  suryaLife = CUST_SURYA_LIFE;
  caliperAuto = CALIPER_AUTO;
  jayashreeEnt = JAYASHREE_ENT;
  sgControl = SGCONTROL;
  eleLte = CUST_ETE_ELE;
  edenSports = EDEN_SPORTS;
  dottedBgImg = DOTTED_BG_IMG;
  dottedImgFull = DOTTED_BG_IMG_FULL;
  promptSupportIcon = PROMPT_SUPPORT_ICON;
  eppsSuccessDrivers = THE_EPPS_SUCCESS_DRIVERS;
  eppsSmartErpAdv = EPPS_SMART_ERP_ADVANTAGE;

  title = 'Best Cloud ERP System for Small & Medium Business';

  constructor(private titleService: Title, private metaService: Meta) { }

  ngOnInit() {
    AOS.init({
      duration: 2000,
      once: true
    });
    this.titleService.setTitle(this.title);
    this.metaService.addTags([
      { name: 'keywords', content: 'ERP, Smart ERP, EPPS' },
      { name: 'description', content: '' }
    ]);
  }
}
