import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApplyJobService {
  url = environment.url;
  headers = new HttpHeaders();

  constructor(private http: HttpClient) {
    this.headers.append('Content-Type', 'multipart/form-data');
  }

  saveJob(data) {
    return this.http.post(this.url + 'submitResume', data, {
      headers: this.headers
    });
  }
}
