import { Component, OnInit } from '@angular/core';
import {
    EASY_MIGRATION_IMAGE_PATH,
    LEADERSHIPBG,
    MILINDPHOTO, JYOTISHPHOTO,
    ATULPIMPLEPHOTO,
    ASHEERKAPOORPHOTO,
    LINKEDINICON,
    LEADING_BANNER_NEW,
    ATUL_SIR_NEW_PIC,
    MILIND_SIR_NEW_PIC,
    MUKTAAKAMPLIKAR,
    Abhinesh,
    Sourabh,
    Harshad,
    jitendra,
    SANJEEVSABHARWAL,
    GANESHNATRAJAN,
    POOJAPHOTO,
    AAMODPHOTO,
    Archana
} from '../../../assets/assets_constant';
@Component({
    selector: 'app-leadership-and-philosophy',
    templateUrl: './leadership-and-philosophy.component.html',
    styleUrls: ['./leadership-and-philosophy.component.css']
})
export class LeadershipAndPhilosophyComponent implements OnInit {
    imagePath = EASY_MIGRATION_IMAGE_PATH;
    bannerImage: String = LEADING_BANNER_NEW;
    asheerPhoto = ASHEERKAPOORPHOTO;
    atulPhoto = ATUL_SIR_NEW_PIC;
    sanjeevphoto = SANJEEVSABHARWAL;
    milindPhoto = MILIND_SIR_NEW_PIC;
    muktaphoto = MUKTAAKAMPLIKAR;
    jyotishPhoto = JYOTISHPHOTO;
    ganeshPhoto = GANESHNATRAJAN;
    poojaphoto = POOJAPHOTO;
    Abhinesh = Abhinesh;
    Sourabh = Sourabh;
    Harshad = Harshad;
    jitendra = jitendra;
    AAMODPHOTO = AAMODPHOTO;
    Archana = Archana;

    constructor() { }

    ngOnInit() {
    }

}
