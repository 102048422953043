import { Component, OnInit, ViewChild } from '@angular/core';
import {
  EASY_MIGRATION_IMAGE_PATH,
  BANNER_IMG,
  CAREERTESTIMGONE,
  CAREERTESTIMGTWO,
  CAREERTESTIMGTHREE,
  CAREERTESTIMGFOUR,
  CAREERTESTIMGFIVE,
  CAREER,
  POSHSLOGO,
  LEADERSHIPBG,
  MILINDPHOTO, JYOTISHPHOTO,
  ATULPIMPLEPHOTO,
  ASHEERKAPOORPHOTO,
  GANESHNATRAJAN,
  SANJEEVSABHARWAL,
  MUKTAAKAMPLIKAR,
  LINKEDINICON,POOJAPHOTO,
  LEADING_BANNER_NEW,
  ATUL_SIR_NEW_PIC,
  MILIND_SIR_NEW_PIC,
  jitendra,
  Harshad,
  Abhinesh,
  Archana
} from '../../assets/assets_constant';

import { SelectionModel } from '@angular/cdk/collections';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import {NgbModal, ModalDismissReasons} from '@ng-bootstrap/ng-bootstrap';

export interface DataElement {
  year: number;
  title: string;
//   categories: string;
  link: string;
}

const ELEMENT_DATA: DataElement[] = [
    // { year: 2021, title: '', link: '' },
    // { year: 2022, title: '', link: '' },
    // { year: 2020, title: '', link: '' },
  ];
const annual_data: any[] = [
    { year: 2021, title: 'Annual Report', link: '../assets/pdf/EPPS_INFOTECH_BSPL_21-22.pdf' },
    { year: 2022, title: 'Annual Report', link: '../assets/pdf/EPPS_INFOTECH_BSPL_22-23.pdf' },
];

@Component({
  selector: 'app-investors',
  templateUrl: './investors.component.html',
  styleUrls: ['./investors.component.css']
})
export class InvestorsComponent implements OnInit {
  imagePath = EASY_MIGRATION_IMAGE_PATH;
  bannerImage: String = CAREER;
  careerTestImgOne = CAREERTESTIMGONE;
  careerTestImgTwo = CAREERTESTIMGTWO;
  careerTestImgThree = CAREERTESTIMGTHREE;
  careerTestImgFour = CAREERTESTIMGFOUR;
  careerTestImgFive = CAREERTESTIMGFIVE;
  sanjeevphoto = SANJEEVSABHARWAL;
  asheerPhoto = ASHEERKAPOORPHOTO;
  muktaphoto = MUKTAAKAMPLIKAR;
  ganeshPhoto = GANESHNATRAJAN;
  atulPhoto = ATUL_SIR_NEW_PIC;
  milindPhoto = MILIND_SIR_NEW_PIC;
  jyotishPhoto = JYOTISHPHOTO;
  poojaphoto = POOJAPHOTO;
  closeResult: string;
  poshs = POSHSLOGO;
  jitendra = jitendra;
  Archana=Archana;
  Harshad = Harshad;
  Abhinesh = Abhinesh;
  constructor(private modalService: NgbModal) { }

  displayedColumns: string[] = ['select', 'year', 'title', 'link'];
  dataSource = new MatTableDataSource(ELEMENT_DATA);
  annualReports = new MatTableDataSource(annual_data);
  selection = new SelectionModel<DataElement>(true, []);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  ngOnInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  applyFilter(event: Event) {
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected === numRows;
  }

  isIndeterminate() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.data.length;
    return numSelected > 0 && numSelected < numRows;
  }

  masterToggle() {
    this.isAllSelected()
      ? this.selection.clear()
      : this.dataSource.data.forEach((row) => this.selection.select(row));
  }

  toggleRow(row: DataElement) {
    this.selection.toggle(row);
  }

  onRowClick(row: DataElement) {
    console.log('Row clicked:', row);
  }

  selectedPerson;
  open(content, person) {
    this.selectedPerson = person;
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title', size: 'lg' }).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
